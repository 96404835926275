body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-card-type-inner .ant-card-body {
    display: none !important;
}

p {
    font-size: 120%;
}
.ant-card-head-wrapper,.ant-card-head{
    background-color: #212d63!important;
    color:white;
    /*padding: 16px;*/
    border-radius: 5px!important;

}

@media only screen and (min-width: 500px) {
    .ant-card-head-title {
        font-size: 20px !important;
    }
    .download{
        font-size: 20px !important;
    }
}
.ant-card-bordered{
    border: none!important;
}


@media only screen and (min-width: 500px) {

    #root > div > div > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap {
        padding: 0 145px;
    }

}

@media only screen and (max-width: 500px) {
    .ant-tabs, .ant-tabs-top, .ant-tabs-mobile, .ant-tabs-card {
        padding: 15px !important;
    }
}

.docu-orthomesh{
    max-width: 400px;
    margin: auto;
    padding: 35px;
    background-color: #3d2163;
    border-radius: 4px;
    color:white;
    /*transition: box-shadow .1s;*/
}
.docu-orthomesh:hover {
    box-shadow: 0px 2px 1px 1px #888888;
    cursor: pointer;
}
.ortho-about-p{
    padding: 20px;
    background-color: #3d2163;;
    border-radius: 5px;
    color:white;
}

#header{
    padding-bottom: 20px;
    color: white;
    text-align: center;
    background-color: rgb(33, 99, 87);
    letter-spacing: 1px;
    font-weight: 100;
    padding-top: 20px;

}

@media only screen and (min-width: 500px) {
    #header{
        font-size: 40px;
    }
}

@media only screen and (max-width: 499px) {
    #header{
        font-size: 18px;
    }
}

.copy{
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #61dafb;
}

#footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3rem;
    text-align: center;
    color: white;
    background-color: #282c34;
}
